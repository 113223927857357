import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Link } from 'react-router-dom'

const cardsData = [
  {
    number: 1,
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5_main.jpg?cid=d24191016440720&w=1440&h=1438&scale.option=ignore"
  },
  {
    number: 2,
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-2_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015010720"
  },
  {
    number: 3,
    image:
      'https://images.squarespace-cdn.com/content/v1/5cd347c1a9ab956dea8b5be8/392e4c68-d5ea-4645-87c7-f007f6703b2a/2024-05-22-DivisionKoop013.jpg?format=1000w'
  },
  {
    number: 4,
    image:
      "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/Version%204-2_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020160720"
  },
  {
    number: 5,
    image:
      "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-5_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021410720"
  },
  {
    number: 6,
    image:
      "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%205/171A1759_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020370720"
  }
]

export default function LandingPage () {
  const [showText, setShowText] = useState(true)
  const [showCards, setShowCards] = useState(false)

  // useEffect(() => {
  //   const textTimer = setTimeout(() => {
  //     setShowText(false)
  //   }, 5000)

  //   const cardTimer = setTimeout(() => {
  //     setShowCards(true)
  //   }, 5500)

  //   return () => {
  //     clearTimeout(textTimer)
  //     clearTimeout(cardTimer)
  //   }
  // }, [])

  return (
    <>
      <div className='flex flex-col items-center justify-center min-h-[60vh] bg-black p-12 shadow-xl text-center'>
        <CSSTransition
          in={showText}
          timeout={300}
          classNames='fade'
          unmountOnExit
        >
          <div>
            <h2 className='sm:text-2xl md:text-4xl font-bold mb-8 text-white halvetica-font'>
              EMPOWERING ART WITH PURPOSE
            </h2>
            <p className='sm:font-small md:font-medium md:text-2xl text-white halvetica-font'>
              I am committed to donating 100% of the proceeds from the sale of
              each of my artworks to a charity or organization of your choice.
              This ensures that my art will emerge with the purest commitment to
              making the world a better place and reflecting my genuine desire
              to create a legacy of art with purpose.
            </p>
            {/* <p className='sm:font-small md:font-medium md:text-2xl text-white halvetica-font'>
              You become a vital part of the creative process, directing the
              impact of your purchase to the cause that matters most to you.
              Whether it’s supporting education, fighting hunger, protecting the
              environment, or any other mission close to your heart, your
              decision will shape where the funds go.
            </p> */}
          </div>
        </CSSTransition>
        {/* 
        <TransitionGroup className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:px-0 lg:px-8 mx-auto'>
          {showCards &&
            cardsData.map((card, index) => (
              <CSSTransition key={index} timeout={500} classNames='card'>
                <div className='p-4 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105'>
                  <img
                    src={card.image}
                    alt=''
                    className='w-full h-48 object-cover rounded-lg'
                  />
                </div>
              </CSSTransition>
            ))}
        </TransitionGroup> */}
        {showText && (
          <Link to='/model/1'>
            <div className='flex flex-col items-center justify-center min-h-[10vh] mt-5'>
              <button className='bg-white text-black font-bold mt-2  py-2 px-20 rounded shadow-md hover:bg-white hover:text-black transform hover:scale-105 transition-transform duration-300 ease-in-out'>
                Buy
              </button>
            </div>
          </Link>
        )}
      </div>
      {/* <button className="mt-2 text-align-center bg-black text-white font-bold py-2 px-10 rounded shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
        Buy
      </button> */}
    </>
  )
}
