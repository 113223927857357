// Modal.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import { useCart } from '../pages/common/CartContext';

interface ModalProps {
    type: 'success' | 'cancel';
    message: string;
}

const Modal: React.FC<ModalProps> = ({ type, message }) => {
    const navigate = useNavigate(); 
    const { clearCart } = useCart();
    clearCart();
    localStorage.removeItem('cart')
    const handleClose = () => {
        navigate('/'); // Redirect to home page
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className={`bg-white p-6 rounded-lg shadow-lg ${type === 'success' ? 'border-green-500' : 'border-red-500'} border-2`}>
                <h2 className={`text-xl font-semibold mb-4 ${type === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                    {type === 'success' ? 'Payment Successful!' : 'Payment Cancelled'}
                </h2>
                <p className="mb-4 text-gray-700">{message}</p>
                <button
                    onClick={handleClose}
                    className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                >
                    Okay
                </button>
            </div>
        </div>
    );
};

export default Modal;
