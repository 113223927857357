import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import getModelImages from '../data'
import { useCart } from '../pages/common/CartContext'
import ConfirmationModal from '../components/ConfirmationModal'
import { showToast } from '../hooks/toastUtils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'

const slugToProductId: Record<string, string> = {
  MRC: 'prod_QgatiwZcNtFpmA',
  DDL: 'prod_QgahGO5Gn5i942',
  KCR: 'prod_QgafvhGM90dufH',
  ADG: 'prod_QgabxgmBT2VlQH',
  KEH: 'prod_QgaY7o2XeYkK4T',
  TAS: 'prod_QgaVMqOTqaxbkX',
  RRF: 'prod_QgaTG69MnDq6Fm',
  JDB: 'prod_QgaSZyumvyZYbt',
  'BGK-C & SCC': 'prod_QgaQ3X75OxQyEo'
}

interface DimensionDetail {
  id: string
  dimensions: string
  price?: number
  totalFractions: number
  remainingFractions: number
}

interface Dimensions {
  inch: string
  cm: string
}

interface ProductDetails {
  dimensionsInInches: string
  dimensionsInCms: string
}

const dimensions: Record<string, Dimensions> = {
  'BGK-C & SCC': {
    inch: '156 inch / 60 inch',
    cm: '396cm / 153cm'
  },
  JDB: {
    inch: '168 inch / 66 inch',
    cm: '427cm / 168cm'
  },
  RRF: {
    inch: '168 inch / 66 inch',
    cm: '427cm / 168cm'
  },
  MRC: {
    inch: '168 inch / 66 inch',
    cm: '427cm / 168cm'
  },
  TAS: {
    inch: '156 inch / 60 inch',
    cm: '396cm / 153cm'
  },
  KEH: {
    inch: '168 inch / 66 inch',
    cm: '427cm / 168cm'
  },
  ADG: {
    inch: '90 inch / 65 inch',
    cm: '230cm / 165cm'
  },
  KCR: {
    inch: '90 inch / 65 inch',
    cm: '230cm / 165cm'
  },
  DDL: {
    inch: '90 inch / 65 inch',
    cm: '230cm / 165cm'
  },
  'Model T': {
    inch: '80 inch / 60 inch',
    cm: '201cm / 152cm'
  }
}

const backendUrl = process.env.REACT_APP_BACKEND_URL

const Model2Slug = () => {
  const { slug } = useParams() as { slug: string }
  const { addToCart } = useCart()
  const dimension: Dimensions | undefined = dimensions[slug]
  const [images, setImages] = useState(getModelImages(2, slug))
  const [selectedImage, setSelectedImage] = useState(images[0])
  const [quantity, setQuantity] = useState(1)
  const [productDetails, setProductDetails] = useState<DimensionDetail | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setIsLoading(true)
        setError(null)

        const productId = slugToProductId[slug]
        if (productId) {
          const response = await fetch(
            `${backendUrl}/api/products/${productId}`
          )

          if (!response.ok) {
            throw new Error('Network response was not ok.')
          }

          const data = await response.json()

          if (data) {
            setProductDetails({
              id: data.id,
              dimensions: data.description,
              price: data.price,
              totalFractions: data.totalFractions || 0,
              remainingFractions: data.remainingFractions || 0
            })
          }
        } else {
          setError('Product ID not found for the given slug')
        }
      } catch (error) {
        setError('Error fetching product details: ' + error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProductDetails()
  }, [slug])

  const handleQuantityChange = (increase: boolean) => {
    setQuantity(prevQuantity =>
      increase ? prevQuantity + 1 : Math.max(prevQuantity - 1, 1)
    )
  }

  function handleAddToCart (): void {
    setIsModalOpen(true)
  }

  const handleConfirmAddToCart = (): void => {
    let cartData = {
      id: productDetails?.id,
      slug,
      image: selectedImage,
      price: productDetails?.price,
      quantity
    }
    console.log('cartData', cartData)

    addToCart(cartData)
    setIsModalOpen(false)
    showToast('Item added to cart successfully!')
  }

  return (
    <div className='md:px-10 px-8 mx-auto'>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className='text-red-500'>{error}</p>
      ) : (
        <>
          <div>
            <p className='pb-2 opacity-50'>Model R</p>
            <p className='sm:text-3xl lg:text-5xl font-semibold'>{slug}</p>
          </div>

          <div className='gap-8 mt-8'>
            {/* Left Section - Image Gallery */}
            <div className='flex-1'>
              <div className='mb-6 '>
                <img
                  src={selectedImage}
                  alt={slug}
                  className='w-full sm:h-[100%] lg:min-h-[400px] lg:max-h-[600px] object-contain transition-all object-fill border-2 border-gray-300'
                />
              </div>

              <div className='flex flex-wrap gap-[10px]'>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    className={`w-16 h-16 object-cover cursor-pointer border-2 transition-all
                  ${
                    selectedImage === image
                      ? 'border-black transform scale-110'
                      : 'border-gray-300'
                  }
                  sm:w-1/2 sm:w-24 sm:h-24`}
                    onClick={() => setSelectedImage(image)}
                  />
                ))}
              </div>
            </div>

            {/* Right Section - Details and Buttons */}
            <div className='flex-1 flex-row py-6 text-gray-800 mb-8 rounded-lg h-full mt-3'>
              <div className='text-gray-800 mb-8'>
                <h1 className='sm:text-2xl md:text-4xl font-medium mb-4'>
                  {slug}
                </h1>
                {productDetails?.remainingFractions === 0 && (
                  <p className='text-red text-small'>Note : Out of stock</p>
                )}
              </div>

              {/* Display Dimensions and Fraction Details */}
              <div>
                {productDetails && (
                  <>
                    {/* <p className='sm:text-sm md:text-lg mb-2'>
                      Dimensions:{' '}
                      <span className='font-semibold text-gray-700'>
                        {productDetails.dimensions}
                      </span>
                    </p> */}
                    <p className='sm:text-sm md:text-lg mb-2'>
                      Dimensions
                      <p className='font-medium text-gray-700'>
                        {dimension.inch}
                      </p>
                      <p className='font-medium text-gray-700'>
                        {dimension.cm}
                      </p>
                    </p>
                    {/* <p className='sm:text-sm md:text-lg mb-2'>
                      Total Fractions:{' '}
                      <span className='font-semibold text-green-600'>
                        {productDetails.totalFractions}
                      </span>
                    </p>
                    <p className='sm:text-sm md:text-lg mb-2'>
                      Remaining Fractions:{' '}
                      <span className='font-semibold text-red-600'>
                        {productDetails.remainingFractions}
                      </span>
                    </p> */}
                    {productDetails.price && (
                      <p className='sm:text-sm md:text-lg mb-2'>
                        Price:{' '}
                        <span className='font-semibold text-gray-700'>
                          ${productDetails.price}
                        </span>{' '}
                        / fraction
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className='flex items-center flex-wrap justify-between mt-8'>
                <div className='flex items-center gap-2  mb-2'>
                  <button
                    onClick={() => handleQuantityChange(false)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    -
                  </button>
                  <span className='px-4 py-2 bg-gray-100 text-gray-700 rounded-md'>
                    {quantity}
                  </span>
                  <button
                    onClick={() => handleQuantityChange(true)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                <div className='flex items-center flex-wrap gap-[15px] mt-8'>
                  <button
                    className='bg-white text-black border-2 border-black font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    onClick={() => handleAddToCart()}
                    disabled={productDetails?.remainingFractions === 0}
                  >
                    ADD TO CART
                  </button>
                  <Link
                    className='bg-white text-black border-2 border-black font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/model/2'
                  >
                    CONTINUE SHOPPING
                  </Link>

                  <Link
                    className='bg-black text-white font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/cart'
                  >
                    CHECKOUT
                  </Link>
                </div>
                <p className='text-black text-small py-5'>
                  When you purchase a fraction , you are acquiring a share of
                  the artwork, and not the entire piece. This fraction
                  represents your ownership stake in the painting. <br />
                  As a fractional owner, you will not receive the physical
                  painting. Your ownership will be recorded digitally, and you
                  will have proof of your share in the artwork.
                </p>
              </div>
            </div>
          </div>
          {/* Confirmation model  */}

          <ConfirmationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={handleConfirmAddToCart}
          />

          <ToastContainer />
        </>
      )}
    </div>
  )
}

export default Model2Slug
