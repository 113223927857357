import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import getModelImages from '../data'
import { useCart } from '../pages/common/CartContext'
import ConfirmationModal from '../components/ConfirmationModal'
import { showToast } from '../hooks/toastUtils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'

type UserName =
  | 'Joe Burrow'
  | 'Lamar Jackson'
  | 'Ronaldo'
  | 'LeBron James'
  | 'Garry Vee'
  | 'Day Prescott'
  | 'Chris Paul'
  | 'Lanzo Ball'
  | 'Elon Musk'

const slugToProductId: Record<string, string> = {
  'joe-burrow': 'prod_QgbP0LkBA1fwua',
  'lamar-jackson': 'prod_QgbIlRNeJgCxi9',
  ronaldo: 'prod_QgbBBMYH5Jktwp',
  'leBron-james': 'prod_Qgb9mzu6kdpwfM',
  'garry-vee': 'prod_Qgb7I065w2G1gP',
  'day-prescott': 'prod_Qgb5BDZLNgY3Il',
  'chris-paul': 'prod_Qgb3xd6ozG32l4',
  'lanzo-ball': 'prod_Qgb0vhNW8ibOLz',
  'elon-musk': 'prod_Qgax3MhiD3btD9'
}

interface DimensionDetail {
  id: string
  dimensions: string
  price?: number
  totalFractions: number
  remainingFractions: number
}

const backendUrl = process.env.REACT_APP_BACKEND_URL

const Model3Slug = () => {
  const { slug } = useParams() as { slug: string }
  const { addToCart } = useCart()

  const [images, setImages] = useState(getModelImages(3, slug))
  const [selectedImage, setSelectedImage] = useState(images[0])
  const [quantity, setQuantity] = useState(1)
  const [productDetails, setProductDetails] = useState<DimensionDetail | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setIsLoading(true)
        setError(null)
        // const processedName = processSlug(slug);
        // const dimensionDetail = slugToProductId[slug];

        const productId = slugToProductId[slug]
        if (productId) {
          const response = await fetch(
            `${backendUrl}/api/products/${productId}`
          )

          if (!response.ok) {
            throw new Error('Network response was not ok.')
          }

          const data = await response.json()

          if (data) {
            setProductDetails({
              id: data.id,
              dimensions: data.description,
              price: data.price,
              totalFractions: data.totalFractions || 0,
              remainingFractions: data.remainingFractions || 0
            })
          }
        } else {
          setError('Product ID not found for the given slug')
        }
      } catch (error) {
        setError('Error fetching product details: ' + error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProductDetails()
  }, [slug])

  const processSlug = (slug: string): UserName => {
    let processedSlug = slug.replace(/-/g, ' ')
    return processedSlug
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') as UserName
  }

  const processedName = processSlug(slug)

  const handleQuantityChange = (increase: boolean) => {
    setQuantity(prevQuantity =>
      increase ? prevQuantity + 1 : Math.max(prevQuantity - 1, 1)
    )
  }

  function handleAddToCart (): void {
    setIsModalOpen(true)
  }

  const handleConfirmAddToCart = (): void => {
    let cartData = {
      id: productDetails?.id,
      slug,
      image: selectedImage,
      price: productDetails?.price,
      quantity
    }
    console.log('cartData', cartData)

    addToCart(cartData)
    setIsModalOpen(false)
    showToast('Item added to cart successfully!')
  }

  return (
    <div className='md:px-0 px-8 mx-auto'>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className='text-red-500'>{error}</p>
      ) : (
        <>
          <div>
            <p className='pb-2 opacity-50'>Model T</p>
            <p className='sm:text-3xl lg:text-5xl font-semibold'>
              {processedName}
            </p>
          </div>

          <div className='flex flex-col md:flex-row gap-8 mt-8'>
            {/* Left Section - Image Gallery */}
            <div className='flex-1'>
              <div className='mb-6'>
                <img
                  src={selectedImage}
                  alt={slug}
                  className='w-full sm:h-[100%] lg:h-[full] object-fill border-2 border-gray-300'
                />
              </div>

              {/* <div className="flex justify-center">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    className={`w-24 h-24 object-cover cursor-pointer mx-2 border-2 transition-all ${selectedImage === image ? 'border-black transform scale-110' : 'border-gray-300'} ${index === 0 ? 'ml-20' : 'mx-2'}`}
                    onClick={() => setSelectedImage(image)}
                  />
                ))}
              </div> */}
            </div>

            {/* Right Section - Details and Buttons */}
            <div className='flex-1 flex-row py-6 text-gray-800 mb-8 rounded-lg h-full'>
              <div className='text-gray-800 mb-8'>
                <h1 className='sm:text-2xl md:text-4xl font-medium mb-4'>
                  {processedName}
                </h1>

                {productDetails?.remainingFractions === 0 && (
                  <p className='text-red text-small'>Note : Out of stock</p>
                )}
              </div>

              {/* Display Dimensions and Fraction Details */}
              <div>
                {productDetails && (
                  <>
                    <p className='sm:text-sm md:text-lg mb-2'>
                      Dimensions{' '}
                      <p className='font-medium text-gray-700'>
                        {/* {productDetails.dimensions} */}
                        80 inch / 60 inch
                      </p>
                      <p className='font-medium text-gray-700'>201cm / 152cm</p>
                    </p>
                    {/* <p className="sm:text-sm md:text-lg mb-2">
                      Total Fractions: <span className="font-semibold text-green-600">{productDetails.totalFractions}</span>
                    </p>
                    <p className="sm:text-sm md:text-lg mb-2">
                      Remaining Fractions: <span className="font-semibold text-red-600">{productDetails.remainingFractions}</span>
                    </p> */}
                    {productDetails.price && (
                      <p className='sm:text-sm md:text-lg mb-2'>
                        Price:{' '}
                        <span className='font-semibold text-gray-700'>
                          ${productDetails.price}
                        </span>{' '}
                        / fraction
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className='flex items-center flex-wrap justify-between mt-8'>
                <div className='flex items-center gap-2  mb-2'>
                  <button
                    onClick={() => handleQuantityChange(false)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    -
                  </button>
                  <span className='px-4 py-2 bg-gray-100 text-gray-700 rounded-md'>
                    {quantity}
                  </span>
                  <button
                    onClick={() => handleQuantityChange(true)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    +
                  </button>
                </div>
                {/* <button
                  className="bg-gradient-to-r from-purple-400 to-blue-500 text-white font-bold py-3 px-8 rounded-full shadow-lg hover:from-blue-500 hover:to-purple-400 transform hover:scale-110 transition-transform duration-300 ease-in-out"
                  onClick={() => handleAddToCart()}
                >
                  Add to Cart
                </button> */}
              </div>
              <div>
                <div className='flex items-center flex-wrap gap-[15px] mt-8'>
                  <button
                    className=' bg-white text-black border-2 border-black font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    onClick={() => handleAddToCart()}
                    disabled={productDetails?.remainingFractions === 0}
                  >
                    ADD TO CART
                  </button>
                  <Link
                    className='bg-white text-black border-2 border-black font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/model/3'
                  >
                    CONTINUE SHOPPING
                  </Link>

                  <Link
                    className='bg-black text-white font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/cart'
                  >
                    CHECKOUT
                  </Link>
                </div>
                <p className='text-black text-small pt-5 pb-3'>
                  When you purchase a fraction , you are acquiring a share of
                  the artwork, and not the entire piece. This fraction
                  represents your ownership stake in the painting.
                </p>
                <p className='text-black text-small'>
                  As a fractional owner, you will not receive the physical
                  painting. Your ownership will be recorded digitally, and you
                  will have proof of your share in the artwork.
                </p>
              </div>
            </div>
          </div>
          {/* Confirmation model  */}

          <ConfirmationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={handleConfirmAddToCart}
          />

          <ToastContainer />
        </>
      )}
    </div>
  )
}

export default Model3Slug
