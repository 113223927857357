// src/pages/Fractional.tsx

import React from 'react'

const Fractional: React.FC = () => {
  return (
    <div className='min-h-screen py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-4xl mx-auto rounded-lg overflow-hidden'>
        <div className='px-6 py-8'>
          <h1 className='text-2xl font-semibold text-left text-black mb-6'>
            Understanding Fractional ART Ownership
          </h1>

          <p className='text-lg text-black leading-relaxed mb-8'>
            Thank you for your interest in acquiring a piece of my art. Before
            you proceed, it’s important to understand the concept of fractional
            art ownership, as well as the terms of your purchase.
          </p>

          <h2 className='text-2xl font-semibold text-black mb-4'>
            What is Fractional Art Ownership?
          </h2>
          <p className='text-lg text-black leading-relaxed mb-8'>
            Fractional art ownership allows multiple individuals to collectively
            own a single piece of artwork. Instead of purchasing the entire
            artwork, you are purchasing a fraction or share of it. This means
            that while you will hold a stake in the artwork, you do not own the
            entire piece outright.
          </p>

          <h2 className='text-2xl font-semibold text-black mb-4'>
            Key Points to Consider:
          </h2>
          <ul className='text-lg text-black list-disc list-inside ml-6 mb-8'>
            <li>
              Fractional Ownership: When you purchase a share, you are acquiring
              a fraction of the artwork, not the entire piece. This fraction
              represents your ownership stake in the painting.
            </li>
            <li>
              Physical Possession: As a fractional owner, you will not receive
              the physical painting. The artwork will remain in a secure
              location or with a designated custodian. Your ownership will be
              recorded digitally, and you will have proof of your share in the
              artwork.
            </li>
            <li>
              Rights and Benefits: Ownership of a fraction of the artwork does
              not grant you exclusive rights. However, depending on the terms of
              the fractional ownership, you may have a say in certain decisions,
              such as where the proceeds will be donated and what I will do with
              the original painting after all fractions are sold.
            </li>
            <li>
              Investment Consideration: Fractional art ownership can be an
              investment opportunity, allowing you to own a share of high-value
              art. However, like any investment, it comes with risks, and the
              value of your share may fluctuate over time.
            </li>
            <li>
              Resale and Liquidity: You may have the option to sell your
              fractional share on the market, but the ease and timing of such a
              sale will depend on market demand and platform policies.
            </li>
          </ul>

          <p className='text-lg text-black leading-relaxed'>
            If you have any questions or need further clarification, please feel
            free to contact us.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Fractional
