import Model1Card, { Model1CardProps } from "../components/Model1Card";
import ModelCard, { ModelCardProps } from "../components/modelCard";

// const test = () => {
//   // if urlValue is not defined, it will be undefined
//   // if urlValue is defined, it will be a string

//   urlValue = document
//     .querySelector("embed-preview-static")
//     .style.backgroundImage.replace('url("//', "https://");
//     urlValue.searchParams.set("w", "1440");
//     urlValue.searchParams.set("h", "1438");
//   console.log(urlValue.toString());

//   try{
//     urlValue = document
//       .querySelector("embed-preview-static")
//       .style.backgroundImage.replace('url("//', "https://")
//       urlValue.searchParams.set("w", "1440");
//       urlValue.searchParams.set("h", "1438");
//     console.log(urlValue.toString());

//   } catch (error) {
//     if(error instanceof ReferenceError ){
//       let urlValue;
//       urlValue = document
//       .querySelector("embed-preview-static")
//       .style.backgroundImage.replace('url("//', "https://");
//       urlValue.searchParams.set("w", "1440");
//       urlValue.searchParams.set("h", "1438");
//     console.log(urlValue.toString());
//     }

// function foo() {
//   const allThumbnails = document.querySelectorAll(".thumbnail-loaded");
//   const allURLS = [];
//   allThumbnails.forEach((thumbnail) => {
//     let imageRef = thumbnail.style.backgroundImage;
//     imageRef = imageRef.replace('url("//', "https://");
//     imageRef = imageRef.replace('")', "");
//     let urlValue = new URL(imageRef);
//     urlValue.searchParams.set("w", "1440");
//     urlValue.searchParams.set("h", "1438");

//     allURLS.push(urlValue.toString());
//   });
//   return allURLS;
// }

// let urlValue;
// let element = document.querySelector("embed-preview-static") as HTMLElement;
// let styleUrl = element.style.backgroundImage.replace('url("//', "https://");

// styleUrl = styleUrl.replace('")', "");
// urlValue = new URL(styleUrl);

// urlValue.searchParams.set("w", "1440");
// urlValue.searchParams.set("h", "1438");
// return urlValue.toString();
// }

//     new URL(document.querySelector('embed-preview-static').style.backgroundImage.replace('url("//', "https://"))
//   }
// };

const cardsData: ModelCardProps[] = [
  {
    number: 1,
    title: "JAPAN",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5_main.jpg?cid=d24191016440720&w=1440&h=1438&scale.option=ignore",
    modelType: 1,
    slug: "japan",
  },
  {
    number: 2,
    title: "SINGAPORE",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A110_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018090720",

    modelType: 1,
    slug: "singapore",
  },
  {
    number: 3,
    title: "SOUTH KOREA",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1203-3_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015100720",

    modelType: 1,
    slug: "south-korea",
  },
  {
    number: 4,
    title: "PHILIPPINES",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620%20copy%203_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191016540720",

    modelType: 1,
    slug: "philippines",
  },
  {
    number: 5,
    title: "INDIA",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1661_india.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018210720",

    modelType: 1,
    slug: "india",
  },
  {
    number: 6,
    title: "THAILAND",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422%20copy_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015020720",

    modelType: 1,
    slug: "thailand",
  },
  {
    number: 7,
    title: "CHINA",
    image:
      "/china-main.jpg",
    modelType: 1,
    slug: "china",
  },
  {
    number: 8,
    title: "MALAYSIA",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-7_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018390720",

    modelType: 1,
    slug: "malaysia",
  },
  {
    number: 9,
    title: "VIETNAM",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015470720",
    modelType: 1,
    slug: "vietnam",
  },
  {
    number: 10,
    title: "INDONESIA",
    image:
      "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-2_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015010720",

    modelType: 1,
    slug: "indonesia",
  },
];

export default function Model1Page() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-8 mx-auto">
    {cardsData.map((card, index) => (
      <ModelCard key={index} {...card}
      fullHeightImages={true}
      useContain={true} />
    ))}
  </div>
  
  );
}
