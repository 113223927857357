import React from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">
      <div className="bg-gray-900 p-8 rounded-lg shadow-xl max-w-sm w-full text-center">
        <h2 className="text-2xl font-bold text-white mb-6">Confirmation</h2>
        <p className="text-gray-400 mb-8">
          Are you sure you want to proceed with this action?
        </p>
        <div className="flex justify-center gap-4">
          <button
            className="px-6 py-2 bg-white text-black font-semibold rounded-md hover:bg-gray-300 transition-colors"
            onClick={onClose}
          >
            No
          </button>
          <button
            className="px-6 py-2 bg-black text-white font-semibold border border-white rounded-md hover:bg-gray-800 transition-colors"
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
