// App.tsx

import { Outlet, Route, Routes, useLocation } from 'react-router'
import LandingPage from './pages'
import MainLayout from './layouts/mainLayout'
import Model1Page from './pages/model1'
import Model2Page from './pages/model2'
import Model1Slug from './pages/model1_slug'
import { useEffect } from 'react'
import Model2Slug from './pages/model2_slug'
import Model3Page from './pages/model3'
import Model3Slug from './pages/model3_slug'
import CartPage from './pages/common/CartPage'
import CheckoutPage from './pages/common/CheckoutPage'
import { CartProvider } from './pages/common/CartContext'
import Modal from './components/Modal'
import Processing from './pages/Processing'
import About from './pages/about'
import Fractional from './pages/fractional'
import Contact from './pages/Contact'

function App () {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [location])

  return (
    <CartProvider>
      {' '}
      {/* Wrap the application with CartProvider */}
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route path='' element={<LandingPage />} />
          <Route path='about' element={<About />} />
          <Route path='fractional' element={<Fractional />} />
          <Route path='contact' element={<Contact />} />
          <Route path='model' element={<Outlet />}>
            <Route path='1' element={<Outlet />}>
              <Route path='' element={<Model1Page />} />
              <Route path=':slug' element={<Model1Slug />} />
            </Route>
            <Route path='2' element={<Outlet />}>
              <Route path='' element={<Model2Page />} />
              <Route path=':slug' element={<Model2Slug />} />
            </Route>
            <Route path='3' element={<Outlet />}>
              <Route path='' element={<Model3Page />} />
              <Route path=':slug' element={<Model3Slug />} />
            </Route>
          </Route>
          <Route path='cart' element={<CartPage />} />
          <Route path='checkout' element={<CheckoutPage />} />
          <Route path='processing' element={<Processing />} />
          <Route
            path='payment_success'
            element={
              <Modal
                type='success'
                message='Your payment was successful! Thank you for your purchase.'
              />
            }
          />
          <Route
            path='payment_cancel'
            element={
              <Modal
                type='cancel'
                message='Your payment was cancelled. Please try again or contact support.'
              />
            }
          />
        </Route>
      </Routes>
    </CartProvider>
  )
}

export default App
