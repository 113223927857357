import { useNavigate } from 'react-router'
import cn from 'clsx'

export type ModelCardProps = {
  image: string
  number: number
  modelType: 1 | 2 | 3
  title?: string
  slug?: string
  fullHeightImages?: boolean
  useContain?: boolean
}

export default function ModelCard (props: ModelCardProps) {
  const { image, title, slug, modelType, fullHeightImages, useContain } = props
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (slug) {
      navigate(`/model/${modelType}/${slug}`)
    }
  }

  return (
    <div className='container mx-auto'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-6'>
        <div className='pb-4'>
          <div
            onClick={handleNavigate}
            className='flex flex-col items-center cursor-pointer w-full'
          >
            <img
              src={image}
              className={cn('w-full transition-all', {
                'h-[400px]': !fullHeightImages,
                'h-full': fullHeightImages,
                'object-contain': useContain,
                'object-cover': !useContain
              })}
              alt=''
            />
            {title && (
              <span className='mt-2 text-center font-light text-sm text-gray-700'>
                {title}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
