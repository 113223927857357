import React from 'react'

const About: React.FC = () => {
  return (
    <div className='min-h-screen py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-5xl mx-auto overflow-hidden'>
        <div className='px-6 py-8'>
          <h1 className='text-2xl font-semibold text-left text-black mb-5'>
            ABOUT
          </h1>

          <div className='mb-12'>
            {/* Content modeled after the Wanda Koop site */}
            <p className='text-lg text-black leading-relaxed'>
              I am SIXSIX1 , an artist committed to remaining anonymous, for my
              work. Is not about me—it’s about the impact it can make. I have no
              desire for fame or recognition. My purpose is clear: to harness
              the power of art to create positive change in the world.
            </p>
            <p className='text-lg text-black leading-relaxed mt-4'>
              In my lifetime, I will create only 661 artworks and they will be
              divided into 3 models :
            </p>
            <ul className='list-disc list-inside ml-6 mt-2 text-lg text-black'>
              <li>
                Model <strong> A:</strong> 195 pieces
              </li>
              <li>
                Model <strong> R:</strong> 367 pieces
              </li>
              <li>
                Model <strong> T:</strong> 99 pieces
              </li>
            </ul>
            <p className='text-lg text-black leading-relaxed mt-4'>
              Those numbers are not arbitrary; it represents a deliberate choice
              to limit my output, ensuring that each piece is rare. By creating
              scarcity, I aim to increase the value of my works for those who
              invest in it, turning each artwork into an asset.
            </p>
          </div>

          <div>
            <h2 className='text-2xl font-semibold text-black mb-4'>
              PHILANTHROPY
            </h2>
            <p className='text-lg text-black leading-relaxed'>
              I am committed to donating 100% of the proceeds from the sale of
              each of my artworks to a charity or organization of your choice.
              When you purchase a fraction or more of my pieces, you’re not just
              acquiring art; you’re making a tangible difference in the world.
              <br />
              This is more than just a transaction. It’s a collaboration. You
              become a vital part of the creative process, directing the impact
              of your purchase to the cause that matters most to you. Whether
              it’s supporting education, fighting hunger, protecting the
              environment, or any other mission close to your heart, your
              decision will shape where the funds go. <br />
              This is the legacy I choose to leave behind. Not just art, but art
              with purpose
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
