// src/components/Contact.tsx

import React, { useState } from 'react'
import { showToast } from '../hooks/toastUtils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const baseUrl = process.env.REACT_APP_BACKEND_URL
      const response = await fetch(`${baseUrl}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (response.ok) {
        showToast('Your message has been sent successfully!', 'success')
        setFormData({ firstName: '', lastName: '', email: '', message: '' })
      } else {
        showToast('Failed to send message. Please try again later.', 'error')
      }
    } catch (error) {
      showToast('An error occurred. Please try again.', 'error')
    }
  }

  return (
    <div className='min-h-screen flex items-center justify-center p-4'>
      <div className='w-full max-w-7xl rounded-lg overflow-hidden'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-[50px]'>
          <div className='sm:hidden md:block'>
            <img
              src="https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5_main.jpg?cid=d24191016440720&w=1440&h=1438&scale.option=ignore"
              alt='Contact Image'
              className='w-full h-[80%] object-contain'
            />
            {/* <img src="/china-main.jpg" alt="" /> */}
          </div>

          <div className='p-6 sm:p-4 md:p-6 ml-3'>
            <h1 className='text-2xl sm:text-3xl font-bold text-center text-black mb-4 sm:mb-6'>
              CONTACT
            </h1>
            {/* <h6 className="text-lg sm:text-xl font-bold text-left text-black mb-4 sm:mb-6">
              SIXSIX1 STUDIO
            </h6> */}
            <p className='text-base sm:text-lg text-black leading-relaxed mb-4 sm:mb-6'>
              Feel free to reach out if you have any questions or inquiries.
            </p>

            <div className='mb-4 sm:mb-6'>
              <a
                href='mailto:info@sixsix1.com'
                className='text-base sm:text-lg text-black'
              >
                info@sixsix1.com
              </a>
              <br />
              <a
                href='http://sixsix1.com'
                className='text-base sm:text-lg text-black'
              >
                sixsix1.com
              </a>
            </div>

            <form className='space-y-4' onSubmit={handleSubmit}>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div>
                  <input
                    type='text'
                    id='firstName'
                    name='firstName'
                    className='mt-1 p-2 w-full border border-gray-300 rounded-md'
                    placeholder='Your First Name'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <input
                    type='text'
                    id='lastName'
                    name='lastName'
                    className='mt-1 p-2 w-full border border-gray-300 rounded-md'
                    placeholder='Your Last Name'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='mt-1 p-2 w-full border border-gray-300 rounded-md'
                  placeholder='Your Email Address'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <textarea
                  id='message'
                  name='message'
                  className='mt-1 p-2 w-full border border-gray-300 rounded-md'
                  placeholder='Your Message'
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button
                type='submit'
                className='w-full sm:w-auto px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    
  )
}

export default Contact
