// CheckoutButton.tsx

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

interface CheckoutButtonProps {
  items: { id: string; slug: string; price: number; quantity: number }[];
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ items }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const handleClick = async () => {
    const stripe = await stripePromise;

    if (!stripe) {
      console.error('Stripe.js failed to load.');
      return;
    }

    try {
        console.log("Api call",items);
        
      const response = await fetch(`${backendUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const sessionId = await response.json();

      const result = await stripe.redirectToCheckout({ sessionId: sessionId.id });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <button
      role="link"
      onClick={handleClick}
      className="bg-black text-white font-bold py-2 px-4 rounded"
    >
      Checkout
    </button>
  );
};

export default CheckoutButton;
