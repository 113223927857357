import React from 'react';
import { useCart } from '../common/CartContext';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { cart } = useCart();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    // Call your backend to create a payment intent
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: cart }),
    });

    const { clientSecret } = await response.json();

    // Confirm the payment
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.error(error);
    } else {
      console.log('Payment successful', paymentIntent);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;



// const express = require('express');
// const app = express();
// const stripe = require('stripe')('your-secret-key-here');
// const bodyParser = require('body-parser');

// app.use(bodyParser.json());

// app.post('/create-payment-intent', async (req, res) => {
//   const { items } = req.body;

//   const amount = items.reduce((total, item) => total + item.price * 100, 0);

//   const paymentIntent = await stripe.paymentIntents.create({
//     amount,
//     currency: 'usd',
//   });

//   res.send({
//     clientSecret: paymentIntent.client_secret,
//   });
// });

// app.listen(3000, () => {
//   console.log('Server is running on port 3000');
// });
