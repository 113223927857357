import { Outlet, useLocation } from 'react-router'
import Mainbar from './mainbar'
import { useState } from 'react'
import Intro from '../components/Intro'
import { CSSTransition } from 'react-transition-group'

export default function MainLayout () {
  const [showIntro, setShowIntro] = useState(true)
  const [showContent, setShowContent] = useState(false)
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const handleIntroComplete = () => {
    setShowIntro(false)
    setShowContent(true)
  }

  return (
    <div
      className={`${isHomePage ? 'w-full' : 'container w-11/12 mx-auto'} pb-12`}
    >
      {showIntro && <Intro onComplete={handleIntroComplete} />}
      <CSSTransition
        in={showContent}
        timeout={1000}
        classNames='fade-in'
        unmountOnExit
      >
        <div className={isHomePage ? 'w-full' : 'w-11/12 mx-auto'}>
          <Mainbar />
          <Outlet />
        </div>
      </CSSTransition>
    </div>
  )
}
