import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import getModelImages from '../data'
import { useCart } from '../pages/common/CartContext'
import ConfirmationModal from '../components/ConfirmationModal'
import { showToast } from '../hooks/toastUtils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'

type CountryName =
  | 'Japan'
  | 'Singapore'
  | 'South Korea'
  | 'Philippines'
  | 'Malaysia'
  | 'Vietnam'
  | 'India'
  | 'Thailand'
  | 'Indonesia'
  | 'China'

interface DimensionDetail {
  id: string
  dimensions: string
  dimensionsInInches: string
  dimensionsInCms: string
  price?: number
  totalFractions: number
  remainingFractions: number
}

const backendUrl = process.env.REACT_APP_BACKEND_URL

const dimensionDetails: Record<CountryName, DimensionDetail> = {
  Singapore: {
    id: 'prod_QgaCEfuLZcUsKj',
    dimensions: '5ft / 5ft',
    dimensionsInInches: '60 inch / 60 inch',
    dimensionsInCms: '152cm / 152cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  'South Korea': {
    id: 'prod_QgZZDpWRb7ytVo',
    dimensions: '5ft / 5ft',
    dimensionsInInches: '60 inch / 60 inch',
    dimensionsInCms: '152cm / 152cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Philippines: {
    id: 'prod_QgZn5o71M89g2j',
    dimensions: '7ft / 5.4ft',
    dimensionsInInches: '84 inch / 65 inch',
    dimensionsInCms: '213cm / 165cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Malaysia: {
    id: 'prod_QgaGwdMwE8ixLO',
    dimensions: '7.4ft / 5.4ft',
    dimensionsInInches: '89 inch / 65 inch',
    dimensionsInCms: '225cm / 165cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Vietnam: {
    id: 'prod_QgZcyKFdmWobaz',
    dimensions: '7ft / 5.4ft',
    dimensionsInInches: '65 inch / 84 inch',
    dimensionsInCms: '165cm / 213cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  India: {
    id: 'prod_QgaEzXwLvQLBux',
    dimensions: '7.4ft / 5.4ft',
    dimensionsInInches: '89 inch / 65 inch',
    dimensionsInCms: '225cm / 165cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  China: {
    id: 'prod_Qga848ZZzBemGn',
    dimensions: '7ft / 5.5ft',
    dimensionsInInches: '84 inch / 65 inch',
    dimensionsInCms: '213cm / 165cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Thailand: {
    id: 'prod_QgFzCXnIULpSnY',
    dimensions: '7ft / 5.4ft',
    dimensionsInInches: '84 inch / 65 inch',
    dimensionsInCms: '213cm / 165cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Indonesia: {
    id: 'prod_QgFGgA1QUkGa2Q',
    dimensions: '5ft / 5ft',
    dimensionsInInches: '60 inch / 60 inch',
    dimensionsInCms: '152cm / 152cm',
    totalFractions: 30,
    remainingFractions: 10
  },
  Japan: {
    id: 'prod_QgEVsgVwSxoUGV',
    dimensions: '5ft / 5ft',
    dimensionsInInches: '60 inch / 60 inch',
    dimensionsInCms: '152cm / 152cm',
    totalFractions: 30,
    remainingFractions: 10
  }
}

const Model1Slug = () => {
  const { slug } = useParams() as { slug: string }
  const { addToCart } = useCart()

  const [images, setImages] = useState(getModelImages(1, slug))
  const [selectedImage, setSelectedImage] = useState(images[0])
  const [quantity, setQuantity] = useState(1)
  const [productDetails, setProductDetails] = useState<DimensionDetail | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setIsLoading(true)
        setError(null)

        const processedName = processSlug(slug)
        const dimensionDetail = dimensionDetails[processedName]

        if (dimensionDetail?.id) {
          const response = await fetch(
            `${backendUrl}/api/products/${dimensionDetail.id}`
          )

          if (!response.ok) {
            throw new Error('Network response was not ok.')
          }

          const data = await response.json()

          if (data) {
            setProductDetails({
              id: data.id,
              dimensions: data.description,
              dimensionsInInches: dimensionDetail.dimensionsInInches,
              dimensionsInCms: dimensionDetail.dimensionsInCms,
              price: data.price,
              totalFractions: data.totalFractions ? data.totalFractions : 0,
              remainingFractions: data.remainingFractions
                ? data.remainingFractions
                : 0
            })
          }
        } else {
          setError('Product ID not found for the given slug')
        }
      } catch (error) {
        setError('Error fetching product details: ' + error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProductDetails()
  }, [slug])

  const processSlug = (slug: string): CountryName => {
    let processedSlug = slug.replace(/-/g, ' ')
    return processedSlug
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') as CountryName
  }

  const processedName = processSlug(slug)
  const { dimensions, totalFractions, remainingFractions } = productDetails || {
    dimensions: '',
    totalFractions: 0,
    remainingFractions: 0
  }

  const handleQuantityChange = (increase: boolean) => {
    setQuantity(prevQuantity =>
      increase ? prevQuantity + 1 : Math.max(prevQuantity - 1, 1)
    )
  }

  function handleAddToCart (): void {
    setIsModalOpen(true)
  }

  const handleConfirmAddToCart = (): void => {
    let productId = productDetails?.id
    let cartData = {
      id: productId,
      slug: processedName,
      image: selectedImage,
      price: productDetails?.price,
      quantity
    }
    console.log('cartData', cartData)

    addToCart(cartData)
    setIsModalOpen(false)
    showToast('Item added to cart successfully!')
  }

  return (
    <div className='md:px-0 px-8 mx-auto'>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className='text-red-500'>{error}</p>
      ) : (
        <>
          <div>
            <p className='pb-2 opacity-50'>Model A</p>
            <p className='sm:text-3xl lg:text-5xl font-semibold'>
              {processedName}
            </p>
          </div>

          <div className='flex flex-col md:flex-row gap-8 mt-8'>
            {/* Left Section - Image Gallery */}
            <div className='flex-1'>
              <div className='mb-6 sm:max-w-[400px] lg:max-w-full lg:min-h-[200px]'>
                <img
                  src={selectedImage}
                  alt={slug}
                  className='w-full sm:h-[100%] h-full object-fill border-2 border-gray-300'
                />
              </div>

              <div className='flex flex-wrap gap-[10px]'>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    className={`w-16 h-16 object-cover cursor-pointer border-2 transition-all
                  ${
                    selectedImage === image
                      ? 'border-black transform scale-110'
                      : 'border-gray-300'
                  }
                  sm:w-1/2 sm:w-24 sm:h-24`}
                    onClick={() => setSelectedImage(image)}
                  />
                ))}
              </div>
            </div>

            {/* Right Section - Details and Buttons */}
            <div className='flex-1 flex-row py-6 text-gray-800 mb-8 rounded-lg h-full'>
              <div className='text-gray-800 mb-8'>
                <h1 className='sm:text-2xl md:text-4xl font-medium mb-4'>
                  {processedName}
                </h1>
                {productDetails?.remainingFractions === 0 && (
                  <p className='text-red text-small'>Note : Out of stock</p>
                )}
              </div>

              {/* Display Dimensions and Fraction Details */}
              <div>
                {productDetails && (
                  <>
                    <p className='sm:text-sm md:text-lg mb-2'>
                      Dimensions{' '}
                      <p className='font-medium text-gray-700'>
                        {productDetails.dimensionsInInches}
                      </p>
                      <p className='font-medium text-gray-700'>
                        {productDetails.dimensionsInCms}
                      </p>
                    </p>
                    {/* <p className='sm:text-sm md:text-lg mb-2'>
                      Total Fractions:{' '}
                      <span className='font-semibold text-green-600'>
                        {productDetails.totalFractions}
                      </span>
                    </p>
                    <p className='sm:text-sm md:text-lg mb-2'>
                      Remaining Fractions:{' '}
                      <span className='font-semibold text-red-600'>
                        {productDetails.remainingFractions}
                      </span>
                    </p> */}
                    {productDetails.price && (
                      <p className='sm:text-sm md:text-lg mb-2'>
                        Price:{' '}
                        <span className='font-semibold text-gray-700'>
                          ${productDetails.price}
                        </span>{' '}
                        / fraction
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className='flex items-center flex-wrap justify-between mt-8'>
                <div className='flex items-center gap-2 mb-2'>
                  <button
                    onClick={() => handleQuantityChange(false)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    -
                  </button>
                  <span className='px-4 py-2 bg-gray-100 text-gray-700 rounded-md'>
                    {quantity}
                  </span>
                  <button
                    onClick={() => handleQuantityChange(true)}
                    className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                <div className='flex items-center flex-wrap gap-[15px] mt-8'>
                  <button
                    className=' bg-white text-black border-2 border-black font-medium py-3 px-8  hover:scale-110 transition-transform duration-300 ease-in-out'
                    onClick={() => handleAddToCart()}
                    disabled={productDetails?.remainingFractions === 0}
                  >
                    ADD TO CART
                  </button>
                  <Link
                    className='bg-white text-black border-2 border-black font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/model/1'
                  >
                    CONTINUE SHOPPING
                  </Link>

                  <Link
                    className='bg-black text-white font-medium py-3 px-8 hover:scale-110 transition-transform duration-300 ease-in-out'
                    to='/cart'
                  >
                    CHECKOUT
                  </Link>
                </div>
                <p className='text-black text-small pt-5 pb-3'>
                  When you purchase a fraction , you are acquiring a share of
                  the artwork, and not the entire piece. This fraction
                  represents your ownership stake in the painting.
                </p>
                <p className='text-black text-small'>
                  As a fractional owner, you will not receive the physical
                  painting. Your ownership will be recorded digitally, and you
                  will have proof of your share in the artwork.
                </p>
              </div>
            </div>
          </div>

          {/* Confirmation model  */}

          <ConfirmationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={handleConfirmAddToCart}
          />

          <ToastContainer />
        </>
      )}
    </div>
  )
}

export default Model1Slug
