import Model2Card, { Model2CardProps } from '../components/Model2Card'
import ModelCard, { ModelCardProps } from '../components/modelCard'

const cardsData: ModelCardProps[] = [
  {
    image:
      'https://neoval.sirv.com/sixsixone/replacement_images/Version%204-2%20Revision2.jpg',
    slug: 'BGK-C & SCC',
    modelType: 2,
    number: 1,
    title: 'B.G.K-C & S.C.C'
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-5_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021410720",

    slug: 'JDB',
    modelType: 2,
    number: 2,
    title: 'J.D.B'
  },
  {
    image:
      'https://neoval.sirv.com/sixsixone/replacement_images/171A1812%20Edit(3).jpg',
    slug: 'RRF',
    modelType: 2,
    number: 3,
    title: 'R.R.F'
  },

  {
    image:
      'https://neoval.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract_%20ROW%20%23%203/171A1908%203.jpg',
    slug: 'MRC',
    modelType: 2,
    number: 4,
    title: 'M.R.C'
  },

  {
    image:
      'https://neoval.sirv.com/sixsixone/replacement_images/171A1759%20Edit.jpg',
    slug: 'TAS',
    modelType: 2,
    number: 5,
    title: 'T.A.S'
  },
  {
    image:
      'https://neoval.sirv.com/sixsixone/replacement_images/171A2034-3%20copy%203%20Edit(1).jpg',

    slug: 'KEH',
    modelType: 2,
    number: 6,
    title: 'K.E.H'
  },
  {
    image:
      "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021290720",
    slug: 'ADG',
    modelType: 2,
    number: 7,
    title: 'A.D.G'
  },

  {
    image:
      "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5_main_compressed.jpg",

    slug: 'KCR',
    modelType: 2,
    number: 8,
    title: 'K.C.R'
  },

  {
    image:
      "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710_main.jpg",

    slug: 'DDL',
    modelType: 2,
    number: 9,
    title: 'D.D.L'
  }
]

export default function Model2Page () {
  return (
    <div className='mx-auto'>
      <div className='grid grid-cols-1 md:px-0 px-8 gap-6 md:w-4/5 w-full mx-auto h-[100%]'>
        {cardsData.map((card, index) => (
          <ModelCard key={index} {...card} fullHeightImages={true} />
          // index >= cardsData.length - 3
        ))}
      </div>
    </div>
  )
}
