import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Mainbar.css'; // Import the CSS file

export default function Mainbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSubMenuOpen(false); // Close submenu when main menu is toggled
  };

  return (
    <div className='relative flex md:px-0 px-8 mx-auto items-center pt-12 pb-12 xs:flex-row flex-col mx-auto w-11/12'>
      {/* Logo */}
      <div className='flex-grow'>
        <Link
          to='/'
          className='font-semibold text-black text-5xl tracking-wide'
        >
          SIXSIX1
        </Link>
      </div>

      {/* Web Menu (Always visible in desktop view) */}
      <div className='hidden xs:flex xs:items-center xs:gap-4 xs:pt-0 xs:flex-nowrap xs:justify-end w-full web-bar'>
        <Link
          className='font-semibold text-sm text-black opacity-50 hover:opacity-100 transition-all ease-in-out'
          to='/about'
        >
          ABOUT
        </Link>

        {/* ARTWORKS Dropdown */}
        <div className='relative group'>
          <button className='font-semibold text-sm text-black opacity-50 hover:opacity-100 transition-all ease-in-out'>
            ARTWORKS
          </button>
          <div className='absolute top-full left-0 mt-2 bg-white shadow-lg rounded-md whitespace-nowrap z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
            <div className='ticker-container'>
              <Link
                className='block px-4 py-2 text-sm text-black hover:bg-gray-100 ticker-item'
                to='/model/1'
              >
                Model A
              </Link>
              <Link
                className='block px-4 py-2 text-sm text-black hover:bg-gray-100 ticker-item'
                to='/model/2'
              >
                Model R
              </Link>
              <Link
                className='block px-4 py-2 text-sm text-black hover:bg-gray-100 ticker-item'
                to='/model/3'
              >
                Model T
              </Link>
            </div>
          </div>
        </div>

        <Link
          className='font-semibold text-sm text-black opacity-50 hover:opacity-100 transition-all ease-in-out'
          to='/fractional'
        >
          FRACTIONAL
        </Link>

        <Link
          className='font-semibold text-sm text-black opacity-50 hover:opacity-100 transition-all ease-in-out'
          to='/contact'
        >
          CONTACT
        </Link>

        {/* Cart Icon */}
        <Link
          className='font-semibold text-sm text-black opacity-50 hover:opacity-100 transition-all ease-in-out'
          to='/cart'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-cart'
            viewBox='0 0 16 16'
          >
            <path d='M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
          </svg>
        </Link>
      </div>

      {/* Hamburger Icon for Mobile (Ensure it's hidden on desktop) */}
      <div className='block xs:hidden humburger'>
        <button onClick={toggleMenu} className='text-black focus:outline-none'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='currentColor'
            className='bi bi-list'
            viewBox='0 0 16 16'
          >
            <path
              fillRule='evenodd'
              d='M3 12.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z'
            />
          </svg>
        </button>
      </div>

      {/* Full-Screen Mobile Menu Overlay (Ensure it's visible only when menu is open) */}
      {isMenuOpen && (
        <div className='fixed inset-0 bg-white z-50 flex flex-col items-center justify-center'>
          <button
            onClick={toggleMenu}
            className='absolute top-4 right-4 text-black focus:outline-none'
          >
            {/* Close Icon */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-x-lg'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
              />
            </svg>
          </button>
          <nav className='flex flex-col items-center space-y-4'>
            <Link
              className='font-semibold text-lg text-black'
              to='/about'
              onClick={toggleMenu}
            >
              ABOUT
            </Link>

            <button
              className='font-semibold text-lg text-black'
              onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
            >
              ARTWORKS
            </button>

            {/* Submenu for ARTWORKS */}
            {isSubMenuOpen && (
              <div className='flex flex-col items-center space-y-2'>
                <Link
                  className='block text-sm text-black hover:bg-gray-100'
                  to='/model/1'
                  onClick={toggleMenu}
                >
                  Model A
                </Link>
                <Link
                  className='block text-sm text-black hover:bg-gray-100'
                  to='/model/2'
                  onClick={toggleMenu}
                >
                  Model R
                </Link>
                <Link
                  className='block text-sm text-black hover:bg-gray-100'
                  to='/model/3'
                  onClick={toggleMenu}
                >
                  Model T
                </Link>
              </div>
            )}

            <Link
              className='font-semibold text-lg text-black'
              to='/fractional'
              onClick={toggleMenu}
            >
              FRACTIONAL
            </Link>

            <Link
              className='font-semibold text-lg text-black'
              to='/contact'
              onClick={toggleMenu}
            >
              CONTACT
            </Link>

            {/* Cart Icon */}
            <Link
              className='font-semibold text-lg text-black'
              to='/cart'
              onClick={toggleMenu}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='currentColor'
                className='bi bi-cart'
                viewBox='0 0 16 16'
              >
                <path d='M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
              </svg>
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
}
