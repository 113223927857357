import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

const Intro = ({ onComplete }: { onComplete: () => void }) => {
    const [showWord1, setShowWord1] = useState(false);
    const [showWord2, setShowWord2] = useState(false);
    const [showWord3, setShowWord3] = useState(false);
    const [showSixSix1, setShowSixSix1] = useState(false);
    const [hideIntro, setHideIntro] = useState(false);

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setShowWord1(true);
        }, 500);

        const timer2 = setTimeout(() => {
            setShowWord2(true);
        }, 1500);

        const timer3 = setTimeout(() => {
            setShowWord3(true);
        }, 2500);

        const sixSix1Timer = setTimeout(() => {
            setShowSixSix1(true);
            setShowWord1(false);
            setShowWord2(false);
            setShowWord3(false);
        }, 3500);

        const hideTimer = setTimeout(() => {
            setHideIntro(true);
        }, 4500);

        const completeTimer = setTimeout(() => {
            onComplete();
        }, 5500);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(sixSix1Timer);
            clearTimeout(hideTimer);
            clearTimeout(completeTimer);
        };
    }, [onComplete]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50">
            <CSSTransition
                in={!hideIntro}
                timeout={1000}
                classNames="fade-out-up"
                unmountOnExit
            >
                <div className="w-[600px] text-center sm:text-2xl lg:text-4xl font-light text-white">
                    <CSSTransition
                        in={showWord1}
                        timeout={500}
                        classNames="fade-up"
                        unmountOnExit
                    >
                        <span className="inline-block">Gratitude</span>
                    </CSSTransition>
                    <CSSTransition
                        in={showWord2}
                        timeout={500}
                        classNames="fade-up"
                        unmountOnExit
                    >
                        <span className="inline-block font-extrabold font-semibold px-5">
                            Love
                        </span>
                    </CSSTransition>
                    <CSSTransition
                        in={showWord3}
                        timeout={500}
                        classNames="fade-up"
                        unmountOnExit
                    >
                        <span className="inline-block">Compassion</span>
                    </CSSTransition>
                    <CSSTransition
                        in={showSixSix1}
                        timeout={500}
                        classNames="fade-up"
                        unmountOnExit
                    >
                        <div className="mil-reveal-frame show">
                            {/* <p className="mil-reveal-box"></p> */}
                            <p className="mil-h3 mil-muted mil-thin">
                                SixSix1.com
                            </p>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
};

export default Intro;
