type ModelData = {
  [key: number]: {
    [key: string]: {
      images: string[]
    }
  }
}

const modelData: ModelData = {
  1: {
    japan: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5_main.jpg?cid=d24191016440720&w=1440&h=1438&scale.option=ignore",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/171A1167-2.jpg?cid=d24191017270720&w=1440&h=1438&scale.option=ignore",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5%20copy%202.jpg?cid=d24191015540720&w=1440&h=1438&scale.option=ignore",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5%20copy%203.jpg?cid=d24191015570720&w=1440&h=1438&scale.option=ignore",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5-2.jpg?cid=d24191016040720&w=1440&h=1438&scale.option=ignore",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2C%20the%20flags''%20japan%20ZIP/Japan5-2%20copy.jpg?cid=d24191016070720&w=1440&h=1438&scale.option=ignore"
      ]
    },
    singapore: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A110_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018090720",

        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017290720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017240720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017310720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-2%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017380720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-2%20copy%203.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017340720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017110720",
        "https://neoval-direct.sirv.com/sixsixone/model01/Model%201%20%2C%2C%20the%20flags''%20singapore%20ZIP/171A1102-3%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017340720"
      ]
    },
    china: {
      images: [
        "/china-main.jpg",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017040720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017260720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191016310720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191016520720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017250720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag%20''%20china%20ZIP/CHINA%208-12-7.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191016560720"
      ]
    },
    thailand: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422%20copy_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015020720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1257-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014480720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014500720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014500720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014420720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flag''%20thailand%20ZIP/171A1422-2%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014430720"
      ]
    },
    malaysia: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-7_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018390720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018130720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018000720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017590720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018110720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20malaysya%20ZIP/171A1634-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017590720"
      ]
    },
    philippines: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620%20copy%203_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191016540720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015570720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015500720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015460720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015540720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015460720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%20%2C%2Cthe%20flags''%20Phillipines%20ZIP/171A1620-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015470720"
      ]
    },
    india: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1661_india.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018210720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017390720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017490720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017480720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017470720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-7.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017450720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model%201%2C%2C%20the%20flags''%20india%20ZIP%20/171A1645-9.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191017400720"
      ]
    },
    indonesia: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-2_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015010720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014430720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014340720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014390720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/171A1126-2-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014340720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/Indonesia%204-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014340720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20indonesia%20ZIP%20/Indonesia%204-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014340720"
      ]
    },
    vietnam: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015470720",

        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015310720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015180720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015280720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612-7.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015380720",
        "https://neoval-direct.sirv.com/sixsixone/model01/model1%20%2C%2Cthe%20flags''%20vietnam%20ZIP/171A1612-9.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015390720"
      ]
    },
    'south-korea': {
      images: [
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1203-3_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015100720',

        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1203-3-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014540720',
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1203-3-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191014520720',
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1206%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015010720',
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1216%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015450720',
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1223%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015400720',
        'https://neoval-direct.sirv.com/sixsixone/model01/Model1%20the%20flags%20korea%20ZIP/171A1223-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191015180720'
      ]
    }
  },
  2: {
    ADG: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021290720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020220720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020300720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020350720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020320720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''%20abstract''%20ROW%20%23%207/GOLD_Drake_6%20copy-7.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020290720"
      ]
    },
    MRC: {
      images: [
        'https://neoval.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract_%20ROW%20%23%203/171A1908%203.jpg',
        'https://neoval.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract_%20ROW%20%23%203/171A1908%204-2.jpg',
        'https://neoval.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract_%20ROW%20%23%203/171A1908%204.jpg',
        'https://neoval.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract_%20ROW%20%23%203/171A1908%204-3.jpg'

        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021490720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021410720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021440720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022010720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-8.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021330720",
      ]
    },
    RRF: {
      images: [
        'https://neoval.sirv.com/sixsixone/replacement_images/171A1812%20Edit(3).jpg',
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%204/171A1810-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019380720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%204/171A1810-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019360720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%204/171A1810-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019490720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%204/171A1810-8.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019270720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%204/171A1810-10.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019270720"
      ]
    },
    TAS: {
      images: [
        'https://neoval.sirv.com/sixsixone/replacement_images/171A1759%20Edit.jpg',
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%205/171A1762-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019590720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%205/171A1762-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020140720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract%20''%20ROW%20%23%205/171A1762-5.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020120720"
      ]
    },
    'BGK-C & SCC': {
      images: [
        'https://neoval.sirv.com/sixsixone/replacement_images/Version%204-2%20Revision2.jpg',
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/171A1894-3%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018230720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/171A1894-3%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018240720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/Version%204-2%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018280720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/Version%204-2-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018380720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20%20row%20%23%201/Version%204-2_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020160720"
      ]
    },
    JDB: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-5_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021410720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020470720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6%20copy%204.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020580720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6%20copy%205.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020490720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6%20copy%206.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020480720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6-2.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020390720",
        "https://neoval-direct.sirv.com/sixsixone/model02/model%202%20%2C%2C%20abstract''%20ROW%20%232/171A1855-6-3.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191020390720"
      ]
    },
    KEH: {
      images: [
        'https://neoval.sirv.com/sixsixone/replacement_images/171A2034-3%20copy%203%20Edit(1).jpg',
        "https://neoval-direct.sirv.com/sixsixone/model02/Model%202%2C%2C%20abstract''%20ROW%20%23%206/171A2034-3%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021150720",
        "https://neoval-direct.sirv.com/sixsixone/model02/Model%202%2C%2C%20abstract''%20ROW%20%23%206/171A2034-3%20copy%202.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021210720",
        "https://neoval-direct.sirv.com/sixsixone/model02/Model%202%2C%2C%20abstract''%20ROW%20%23%206/171A2034-4.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021070720",
        "https://neoval-direct.sirv.com/sixsixone/model02/Model%202%2C%2C%20abstract''%20ROW%20%23%206/171A2034-7.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191021020720"
      ]
    },
    KCR: {
      images: [
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5_main_compressed.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-2.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-4.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-5.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-6.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%20''Abstract%20''%20ROW%20%23%208/Keanu5-8.jpg"
      ]
    },
    DDL: {
      images: [
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710_main.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-5.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-6.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-8.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-9.jpg",
        "https://neoval.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-11.jpg"

        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-6.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191018590720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-8.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019110720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-9.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019240720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710-11.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019120720",
        // "https://neoval-direct.sirv.com/sixsixone/model02/model%202%2C%2C%20abstract''%20ROW%20%239/171A1710_main.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191019520720",
      ]
    }
  },
  3: {
    'elon-musk': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1407%203.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022370720"
      ]
    },
    'lanzo-ball': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1722%201.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022400720"
      ]
    },
    'chris-paul': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1366.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022190720"
      ]
    },
    'day-prescott': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1343-2%201%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022110720"
      ]
    },
    'garry-vee': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1299%20copy.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022360720"
      ]
    },
    'leBron-james': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1381.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022320720"
      ]
    },
    ronaldo: {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1228.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022080720"
      ]
    },
    'lamar-jackson': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1354%201.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022410720"
      ]
    },
    'joe-burrow': {
      images: [
        "https://neoval-direct.sirv.com/sixsixone/model03/model%203%20%2C%2C%20The%20Numbers''%201-9/171A1397.jpg?w=1440&h=1438&q=60&scale.option=noup&cid=d24191022280720"
      ]
    }
  }
}

const getModelImages = (modelType: 1 | 2 | 3, slug: string) => {
  return modelData[modelType][slug].images
}

export default getModelImages
