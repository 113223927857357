
import React, { createContext, useContext, useReducer, useEffect, ReactNode } from "react";

interface CartItem {
  id: any;
  slug: string;
  image: string;
  price: any;
  quantity: number; // Add quantity property
}

interface CartState {
  items: CartItem[];
}

interface CartContextProps {
  cart: CartState;
  addToCart: (item: CartItem) => void;
  removeFromCart: (id: string) => void;
  updateQuantity: (id: string, quantity: number) => void; 
  clearCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

const cartReducer = (state: CartState, action: any): CartState => {
  switch (action.type) {
    case "ADD_TO_CART": {
      const { id, slug, image, price, quantity } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        return {
          items: state.items.map(item =>
            item.id === id
              ? { ...item, quantity: item.quantity + quantity }
              : item
          ),
        };
      }

      return { items: [...state.items, { ...action.payload, quantity }] };
    }
    case "REMOVE_FROM_CART":
      return { items: state.items.filter((item) => item.id !== action.payload) };
    case "UPDATE_QUANTITY": {
      const { id, quantity } = action.payload;
      return {
        items: state.items.map(item =>
          item.id === id
            ? { ...item, quantity: Math.max(quantity, 1) }
            : item
        ),
      };
    }
    default:
      return state;
  }
};

interface CartProviderProps {
  children: ReactNode;
}

const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, { items: [] }, () => {
    const localData = localStorage.getItem("cart");
    return localData ? JSON.parse(localData) : { items: [] };
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item: CartItem) => {
    dispatch({ type: "ADD_TO_CART", payload: item });
  };

  const removeFromCart = (id: string) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: id });
  };

  const updateQuantity = (id: string, quantity: number) => {
    dispatch({ type: "UPDATE_QUANTITY", payload: { id, quantity } });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateQuantity, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
